#home-hello {
  height: 100%; 
  background-color: #ee5a61; 
  /* padding: 100px;  */
  color: white;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#hello-logo {
  height: 250px;
  margin-top: 5%;
}

#hello-text {
  font-size: x-large; 
  margin: 0 15%;
  transition: opacity 1s
}

.fade-in {
  opacity: 1
}

.fade-out {
  opacity: 0
}

#wwic {
  font-size: 70px; 
  font-weight: bold
}

#home-design {
  padding: 100px 0;
  font-size: xxx-large; 
  font-weight: 800
}

#work-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 1vw
}

#auth-modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.auth-modal-wrap {
  background-color: #ee5a61 !important;
  color: white;
  font-weight: 600;
  padding-top: 60px;
  padding-bottom: 60px;
}

.auth-modal-wrap input {
  margin-left: 10px;
  border-radius: 5px;
  border: 3px solid white;
}

.auth-modal-wrap input:focus {
  outline: none
}

#auth-submit-btn {
  /* border: 3px solid white; */
  background-color: #ee5a61;
  color: white
}

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media (max-width: 800px) {
  #home-hello {
    padding: 100px 20px
  }

  #hello-logo {
    height: 200px
  }

  #hello-text {
    margin: 0
  }

  #wwic {
    font-size: xx-large
  }

  #work-grid {
    grid-template-columns: auto;
  }

  #auth-modal {
    display: flex;
    flex-direction: column;
  }

  .auth-modal-wrap {
    padding-top: 0;
    padding-bottom: 0
  }

  #auth-modal span {
    margin: -9px 0 10px 0 !important
  }

  #auth-form {
    display: flex;
    justify-content: space-between;
    width: 100%
  }

  #auth-form input {
    margin: 0 !important
  }
}