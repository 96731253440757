.thumb {
  position: relative;
  width: 100%;
  background-color: #47567c
}

.thumb-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.thumb-middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.thumb-title {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: xx-large;
  color: white
}

.thumb:hover .thumb-image {
  opacity: 0.3;
  cursor: pointer
}

.thumb:hover .thumb-middle, .thumb:hover .thumb-title {
  opacity: 1;
  cursor: pointer
}

.thumb-text {
  color: white;
  font-size: 16px;
  padding: 16px 32px;
  cursor: pointer
}

@media (max-width: 800px) {
  .thumb-image {
    opacity: 0.3;
  }

  .thumb-middle, .thumb-title {
    opacity: 1;
  }
}