#top-wrap {
  background-color: white; 
  padding: 60px 0; 
  position: sticky; 
  top: -260px; 
  z-index: 100
}

#top-logo {
  position: relative;
  animation-name: drop;
  animation-duration: 1000ms;
  animation-timing-function: ease
}
@keyframes drop {
  0% {
    top: -300px;
    opacity: 0;
  }
  50% {
    top: 20px;
    opacity: .7;
  }
  75% {
    top: -8px;

  }
  100% {
    top: 0;
    opacity: 1
  }
}

.navigation *, .navigation *:visited {
  padding: 0 10px;
  text-decoration: none !important;
  color: black !important
}

.navigation *:hover {
  text-decoration: underline !important;
  cursor: pointer !important
}

.down-resume, .down-resume:hover {
  padding: 15px 20px; 
  background-color: #ee5a61; 
  color: white;
  font-weight: 600;
  text-decoration: none;
  margin: 5px 0;
  text-align: center; 
  cursor: pointer
}

@media (max-width: 800px) {
  #top-wrap {
    padding: 0; 
    top: -360px; 
    /* position: static */
    height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .navigation {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 5vw;
    position: sticky;
    top: 0px;
    padding-top: 10px 
  }
}